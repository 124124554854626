<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
            <div class="row">
                <div class="col-md-8 d-flex align-items-stretch ">
                    <div class="row flex-grow">
                        <div class="col-12 grid-margin">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title" v-if="form.id == ''">{{ $t('new-question-header') }}</h4>
                                    <h4 class="card-title" v-else>{{ $t('update-question-header') }}</h4>
                                </div>
                                <div class="card-body new-card-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('category*')" label-for="Category">
                                                <b-form-select :options="categories"
                                                                value-field="id"
                                                                text-field="name"
                                                               v-model="$v.form.category_id.$model"
                                                               :state="$v.form.category_id.$dirty ? !$v.form.category_id.$error : null"
                                                               aria-describedby="input-2-live-feedback"
                                                               @change="checkCategory($event)">
                                                               <b-form-select-option v-if="categories.length === 0" :disabled = true>{{ $t('no-category-found') }}</b-form-select-option>
                                                </b-form-select>      
                                                <b-form-invalid-feedback id="input-2-live-feedback">{{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('order*')" label-for="Order">
                                                <!-- <b-form-select-option :value="null">Please select an order</b-form-select-option> -->
                                                <b-form-select :options="orders"
                                                               v-model="form.order"
                                                               aria-describedby="input-4-live-feedback"
                                                                @change="checkOrder($event)">
                                                </b-form-select>
                                            <div id="input-4-live-feedback" v-if="orderError" class="custom-invalid-feedback">
                                                {{ $t("Order already exist for current category") }}
                                            </div>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="row" v-if="!showSubQuestions">
                                        <div class="col-md-12">
                                            <b-form-group :label="$t('question*')" label-for="question">
                                                <b-form-input
                                                        type="text"
                                                        id="question"
                                                        name="question"
                                                        autocomplete="new-password"
                                                        :placeholder="$t('Question?')" 
                                                        v-model="$v.form.question.$model"
                                                        :state="$v.form.question.$dirty ? !$v.form.question.$error : null"
                                                        aria-describedby="input-4-live-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.form.question.required"
                                                                         id="input-4-live-feedback">{{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">
                                            <b-form-group :label="$t('response')" label-for="response">
                                                 <!-- :state="$v.form.response.$dirty ? !$v.form.response.$error : null"
                                                        aria-describedby="input-4-live-feedback" -->
                                                <b-form-input
                                                        type="text"
                                                        id="response"
                                                        name="response"
                                                        autocomplete="new-password"
                                                        :placeholder="$t('Enter Possible Responses separted by commas')" 
                                                        v-model="form.response"
                                                >
                                                </b-form-input>
                                                <!-- <b-form-invalid-feedback v-if="!$v.form.response.required"
                                                                         id="input-4-live-feedback">This is a required
                                                    field.
                                                </b-form-invalid-feedback> -->
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="row" v-if="showSubQuestions">
                                        <div class="col-md-12">
                                            <b-form-group :label="$t('question*')" label-for="question">
                                                <b-form-input
                                                        type="text"
                                                        id="question"
                                                        name="question"
                                                        autocomplete="new-password"
                                                        :placeholder="$t('Question?')"
                                                        v-model="$v.form.question.$model"
                                                        :state="$v.form.question.$dirty ? !$v.form.question.$error : null"
                                                        aria-describedby="input-4-live-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.form.question.required"
                                                                         id="input-4-live-feedback">{{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">
                                            <b-form-group :label="$t('response*')" label-for="response">
                                                <!-- :state="$v.form.response.$dirty ? !$v.form.response.$error : null" -->
                                                <b-form-select v-model="form.response" :options="options"
                                                               @change="onChangeAnswer"></b-form-select>
                                                <!-- <b-form-invalid-feedback v-if="!$v.form.response.required"
                                                                         id="input-4-live-feedback">This is a required
                                                    field.
                                                </b-form-invalid-feedback> -->
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div v-if="showSubQuestions && (form.response === 'yes')">
                                        <div class="row" v-for="(subQuestion, index) in form.subQuestions" :key="index">
                                            <div class="col-md-5">
                                                <b-form-group :label="$t('question*')" label-for="response">
                                                    <b-form-input
                                                            type="text"
                                                            id="response"
                                                            name="response"
                                                            autocomplete="new-password"
                                                            :placeholder="$t('Enter Possible Responses separted by commas')" 
                                                            v-model="subQuestion.question"
                                                            aria-describedby="input-4-live-feedback"
                                                    >
                                                    </b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-5">
                                                <b-form-group :label="$t('response')" label-for="response">
                                                    <b-form-input
                                                            type="text"
                                                            id="response"
                                                            name="response"
                                                            autocomplete="new-password"
                                                            :placeholder="$t('Enter Possible Responses separted by commas')" 
                                                            v-model="subQuestion.answer"
                                                            aria-describedby="input-4-live-feedback"
                                                    >
                                                    </b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-2">
                                                <b-button type="button" v-if="index === 0" variant="success"
                                                          class="mr-2 btn-sm mt-4"
                                                          @click="addQuestion">
                                                   {{ $t('add-new-btn')}}
                                                </b-button>
                                                <b-button type="button" v-if="index !== 0" variant="danger"
                                                          class="mr-2 btn-sm mt-4"
                                                          @click="removeQuestion(subQuestion)">
                                                          {{ $t('remove-btn')}}
                                                </b-button>
                                            </div>
                                        </div>
                                    </div>
                                    <b-button type="submit" variant="success" :disabled="isDisabled" class="mr-2">
                                        <span v-if="form.id == ''">{{ $t('submit-btn') }}</span>
                                        <span v-else>{{ $t('update-btn') }}</span>
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 align-items-stretch grid-margin">
                    <div class="card">
                        <div class="card-body new-card-body">
                            <div class="col-md-12 card-toggles">
                                <label class="select-pref"
                                       for="inline-form-custom-select-pref">{{ $t('response-require') }}</label>
                                <toggle-button class="switch-button switchColor"
                                               v-model="form.required" :sync="true"/>
                            </div>
                            <div class="col-md-12 card-toggles">
                                <label class="select-pref"
                                       for="inline-form-custom-select-pref">{{ $t('show-question') }}</label>
                                <toggle-button class="switch-button switchColor" v-model="form.show"
                                               :sync="true"/>
                            </div>
                           
                            <div class="col-md-12 card-toggles">
                                <label class="select-pref"
                                       for="inline-form-custom-select-pref">{{ $t('map-filterable') }}</label>
                                <toggle-button class="switch-button switchColor" v-model="form.map_filterable" :sync="true"/>
                            </div>
                            <div class="col-md-12 card-toggles">
                                <label class="select-pref" for="inline-form-custom-select-pref">{{ $t('chart-answer') }}</label>
                                <toggle-button class="switch-button switchColor" v-model="form.chart_answer"
                                               :sync="true"/>
                            </div>
                            <div class="col-md-12 card-toggles">
                                <label class="select-pref"
                                       for="inline-form-custom-select-pref">{{ $t('countable') }}</label>
                                <toggle-button class="switch-button switchColor"
                                               v-model="form.is_countable" :sync="true"/>
                            </div>
                            <div class="col-md-12 card-toggles">
                                <label class="select-pref"
                                       for="inline-form-custom-select-pref">{{ $t('add-yes-no-logic') }}</label>
                                <toggle-button class="switch-button switchColor"
                                               v-model="form.logic" @change="onChangeEventHandler"
                                               :sync="true"/>
                            </div>
                        </div>
                    </div>
                    <h6 class="mt-2 shared-data">{{ $t('shared-data') }}</h6>
                    <div class="card mt-3">
                        <div class="card-body new-card-body">
                            <div class="col-md-12 card-toggles">
                                <label class="select-pref"
                                       for="inline-form-custom-select-pref">{{ $t('spotter-question') }}</label>
                                <toggle-button class="switch-button switchColor" v-model="form.spotter_question"
                                               :sync="true"/>
                            </div>
                            <div class="col-md-12 card-toggles">
                                <label class="select-pref" for="inline-form-custom-select-pref">{{ $t('chart-on-spotter') }}</label>
                                <toggle-button class="switch-button switchColor" v-model="form.show_on_spotter"
                                               :sync="true"/>
                            </div>
                            <div class="col-md-12 card-toggles">
                                <label class="select-pref" for="inline-form-custom-select-pref">{{ $t('show-on-spot-details') }}</label>
                                <toggle-button class="switch-button switchColor" v-model="form.show_answer_on_spotter"
                                               :sync="true"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="row">
            <div class="col-lg-12 stretch-card">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex align-items-center question-order row-question-order">
                            <h4 class="card-title">{{ $t('data-collection-questions') }}</h4>
                            <div class="header-buttons">
                                <h4 class="card-title mr-4" v-if="this.version_id === 2 && this.is_pro_sync === 1">{{ $t('Questions By Survey') }}</h4>
                                <div class="header-buttons mr-4" v-if="this.version_id === 2 && this.is_pro_sync === 1">
                                    <select class="form-control form-select-sm" id="categorySelect" @change="onProjectChange" style="height: 38px" v-model="selectedProjectId">
                                        <option value="" selected>{{ $t('Select Survey') }}</option>
                                        <option v-for="project in projectOptions" :value="project.id" :key="project.id">
                                            {{ project.name }}
                                        </option>
                                    </select>
                                </div>
                                <button class="btn btn-primary" @click="ShowExportModal">{{ $t('export-btn') }}</button>
                                <button class="btn btn-primary ml-3" @click="ShowModal">{{ $t('edit-question-order-btn') }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body new-card-body">
                        <vue-good-table
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-column-filter="onColumnFilter"
                                @on-per-page-change="onPerPageChange"
                                @on-search="searchFn"
                                :search-options="{enabled: true,searchFn: searchFn}"
                                :totalRows="totalRecords"
                                :isLoading.sync="isLoading"
                                :pagination-options="{
                                enabled: true,
                                dropdownAllowAll: false,
                                perPageDropdown: [10, 20, 50, 100, 200],
                            }"
                                :rows="rows"
                                :columns="columns"
                                class="pd-table">
                            <template slot="table-row" slot-scope="props">
                                <span
                                        v-if="props.column.field === 'action'"
                                        class="text-nowrap"
                                >
                                    <b-button size="sm" @click="editQuestion(props.row.id)"
                                            class="mr-1 btn-info text-white">
                                {{ $t('edit-btn') }}
                            </b-button>
                            <b-button size="sm" @click="deleteQuestion(props.row.id)"
                                        class="mr-1 btn-danger text-white">
                                {{ $t('delete-btn') }}
                            </b-button>
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
        <b-modal id="spot-detail" :title="$t('edit-questions-order')" size="md" :hide-footer="true">
            <div class="row">
                <div class="col-md-12">
                    <b-form-group :label="$t('category*')" label-for="Category">
                        <b-form-select :options="categories" value-field="id" text-field="name"
                            v-model="$v.form.category_id.$model"
                            :state="$v.form.category_id.$dirty ? !$v.form.category_id.$error : null"
                            aria-describedby="input-2-live-feedback"
                            @change="BulkEdit()">
                        </b-form-select>
                    </b-form-group>
                </div>
            </div>
            <div class="modal-body-scroll">
                <div class="row mt-3">
                    <div class="col-md-8">
                        <h5>{{ $t('questions') }}</h5>
                    </div>
                    <div class="col-md-3">
                        <h5>{{ $t('order') }}</h5>
                    </div>
                </div>
                <div class="row mt-3" v-for="row in rowsForBulkEdit" :key="row.id">
                    <div class="col-md-8 align-items-center">
                        <div>
                            <p>{{row.question}}</p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div>
                            <b-form-input type="number" class="custom-order-input" min=1  oninput="this.value = Math.abs(this.value)" required v-model.number="row.order"></b-form-input>
                            <!-- <b-form-invalid-feedback v-if="!$v.orderForm.order.required" id="input-4-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback> -->
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row m-1 float-right">
                <b-button  type="button" variant="success" class="btn btn-primary btn-sm d-flex" @click="matchingOrder">{{ $t('save-changes') }}</b-button>
            </div>
        </b-modal>

        <!-- export modal -->
        <b-modal id="export-modal" :title="$t('export-template')" size="md" :hide-footer="true">
            <div class="row">
                <div class="col-md-12">
                    <b-form-group :label="$t('category*')" label-for="Category">
                        <b-form-select :options="categories" value-field="id" text-field="name"
                            v-model="$v.form.category_id.$model"
                            :state="$v.form.category_id.$dirty ? !$v.form.category_id.$error : null"
                            aria-describedby="input-2-live-feedback"
                            @change="BulkEdit()">
                        </b-form-select>
                    </b-form-group>
                </div>
            </div>
            <div class="modal-body-scroll">
                <div class="row mt-3">
                    <div class="col-md-8">
                        <h5>{{ $t('questions') }}</h5>
                    </div>
                    <!-- <div class="col-md-3">
                        <h5>Order</h5>
                    </div> -->
                </div>
                <div class="row" v-for="(row, index) in rowsForBulkEdit" :key="row.id">
                    <div class="col-md-8 align-items-center">
                        <div>
                            <p>{{ index+1 }}.  {{row.question}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row m-1 float-right">
                <button :disabled="isLoading"  type="button" variant="success" class="btn btn-success btn-sm d-flex" @click="ExportTemplate">
                    <template v-if="isLoading">
                                        <i class="fas fa-spinner fa-spin"></i> {{ $t('exporting') }}...
                                    </template>
                                    <template v-else>
                                        {{ $t('export-template') }} 
                                    </template></button>
            </div>
        </b-modal>
    </section>
    
</template>

<script>
    import Vue from "vue";
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import {validationMixin} from "vuelidate";
    import {required, minLength, email, requiredIf, sameAs} from "vuelidate/lib/validators";

    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    export default {
        name: 'Users',
        mixins: [validationMixin],
        data() {
            
            return {
                form: {
                    category_id: '',
                    order: Number,
                    question: "",
                    response: "",
                    logic: false,
                    required: true,
                    chart_answer: false,
                    show_on_spotter: false,
                    spotter_question:true,
                    map_filterable:false,
                    show_answer_on_spotter:true,
                    show: true,
                    is_countable: false,
                    id: "",
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    subQuestions: [
                        {
                            question: '',
                            answer: '',
                        }
                    ]
                },
                orderForm: {
                    category_id: '',
                    order: Number,
                    question: ''
                },
                orderValue: '',
                version_id: null,
                is_pro_sync: null,
                selectedProjectId: null,
                projectOptions: [],
                orderError: false,
                showSubQuestions: false,
                serverParams: {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    // a map of column filters example: {name: 'john', age: '20'}
                    columnFilters: {},
                    sort: [
                        {
                            field: 'id', // example: 'name'
                            type: 'desc' // 'asc' or 'desc'
                        }
                    ],
                    selectedProjectId: null,
                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                options: {
                    'yes': 'Yes',
                    'no': 'No',
                    'CBD': 'CBD'
                },
                columns: [
                    {
                        label: 'Order',
                        field: 'order',
                        filterable: true,
                    },
                    {
                        label: 'Category',
                        field: 'category_name',
                        filterable: true,
                        sortable: true,
                        // formatFn: this.formatCategory
                    },
                    {
                        label: 'Question',
                        field: 'question',
                        filterable: true
                    },
                    {
                        label: 'Responses',
                        field: 'responses',
                        filterable: true,
                        sortable: false,
                        // formatFn: this.formatResponses,
                    },
                    {
                        label: 'Responses Required',
                        field: 'required',
                        sortable: true,
                        formatFn: this.formatFn,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: '-',
                            filterValue: '',
                            filterDropdownItems: [
                                { value: '0', text: 'No' },
                                { value: '1', text: 'Yes' },
                                { value: '2', text: 'CBD' },
                            ],
                            filterFn: this.columnFilterFn,
                            trigger: 'enter',
                        },
                    },
                    {
                        label: 'Show Question',
                        field: 'show',
                        sortable: true,
                        formatFn: this.formatFn,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: '-',
                            filterValue: '',
                            filterDropdownItems: [
                                { value: '0', text: 'No' },
                                { value: '1', text: 'Yes' },
                            ],
                            filterFn: this.columnFilterFn,
                            trigger: 'enter',
                        },
                    },
                    {
                        label: 'Spotter Question',
                        field: 'spotter_question',
                        sortable: true,
                        formatFn: this.formatFn,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: '-',
                            filterValue: '',
                            filterDropdownItems: [
                                { value: '0', text: 'No' },
                                { value: '1', text: 'Yes' },
                            ],
                            filterFn: this.columnFilterFn,
                            trigger: 'enter',
                        },
                    },
                    {
                        label: 'Map Filterable',
                        field: 'map_filterable',
                        sortable: true,
                        formatFn: this.formatFn,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: '-',
                            filterValue: '',
                            filterDropdownItems: [
                                { value: '0', text: 'No' },
                                { value: '1', text: 'Yes' },
                            ],
                            filterFn: this.columnFilterFn,
                            trigger: 'enter',
                        },
                    },
                    {
                        label: 'Chart Answer',
                        field: 'chart_answer',
                        sortable: true,
                        formatFn: this.formatFn,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: '-',
                            filterValue: '',
                            filterDropdownItems: [
                                { value: '0', text: 'No' },
                                { value: '1', text: 'Yes' },
                            ],
                            filterFn: this.columnFilterFn,
                            trigger: 'enter',
                        },
                    },
                    {
                        label: 'Chart on Spotter',
                        field: 'show_on_spotter',
                        sortable: true,
                        formatFn: this.formatFn,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: '-',
                            filterValue: '',
                            filterDropdownItems: [
                                { value: '0', text: 'No' },
                                { value: '1', text: 'Yes' },
                            ],
                            filterFn: this.columnFilterFn,
                            trigger: 'enter',
                        },
                    },
                    {
                        label: 'Show on Spot Details',
                        field: 'show_answer_on_spotter',
                        sortable: true,
                        formatFn: this.formatFn,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: '-',
                            filterValue: '',
                            filterDropdownItems: [
                                { value: '0', text: 'No' },
                                { value: '1', text: 'Yes' },
                            ],
                            filterFn: this.columnFilterFn,
                            trigger: 'enter',
                        },
                    },
                    {
                        label: 'Countable',
                        field: 'is_countable',
                        sortable: true,
                        formatFn: this.formatFn,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: '-',
                            filterValue: '',
                            filterDropdownItems: [
                                { value: '0', text: 'No' },
                                { value: '1', text: 'Yes' },
                            ],
                            filterFn: this.columnFilterFn,
                            trigger: 'enter',
                        },
                    },
                    {
                        label: 'Logic Question',
                        field: 'logic',
                        sortable: true,
                        formatFn: this.formatFn,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: '-',
                            filterValue: '',
                            filterDropdownItems: [
                                { value: '0', text: 'No' },
                                { value: '1', text: 'Yes' },
                            ],
                            filterFn: this.columnFilterFn,
                            trigger: 'enter',
                        },
                    },
                    {
                        label: 'Action',
                        field: "action",
                        sortable: false,
                    },
                ],
                isLoading: false,
                rows: [],
                rowsForBulkEdit: [],
                totalRecords: 0,
                // category_id: '',
                categories: [],
                orders: [],
                isDisabled: false,
            };
        },
        validations: {
            form: {
                id: {},
                question: {
                    required,
                }, 
                // response: {
                //     required,
                // },
                category_id: {
                    required,
                },
            },
            orderForm: {
                category_id: {
                    required
                },
                order: {
                    required
                }
            }
        },
        methods: {
            columnFilterFn(value) {
                console.log('here',value);
            },
            matchingOrder() {
                const getKey = (array,key) => array.map(a => a[key]);
                var findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)
                const question_ids = (getKey( this.rowsForBulkEdit,'id'));
                const order_ids = (getKey( this.rowsForBulkEdit,'order'));
                var duplicateOrder = findDuplicates(order_ids);
                if(duplicateOrder != '') {
                    this.$snotify.error('Question Order '+ duplicateOrder.toString() + ' Already exist.')
                } else {
                    const data = {
                        client_id: JSON.parse(localStorage.getItem('client_id')),
                        question_ids : question_ids,
                        order_ids : order_ids,
                        category_id: this.form.category_id,
                    }
                    API.UpdateOrders(
                        data,
                        data => {
                            this.$root.$emit("bv::hide::modal", "spot-detail");
                            this.loadItems();
                            this.$snotify.success('Questions Order has been updated');
                        },
                        err => {}
                    )
                }
            },

            ShowModal(){
                this.BulkEdit();
                this.$root.$emit("bv::show::modal", "spot-detail");
            },
            ShowExportModal(){
                this.BulkEdit();
                this.$root.$emit("bv::show::modal", "export-modal");
            },
            ExportTemplate(){
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    category_id: this.form.category_id,
                }
                this.isLoading = true;
                API.exportQuestions(
                    data,
                    data => {
                        this.isLoading = false;
                        const href = URL.createObjectURL(data);
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', `question-export-${new Date().toLocaleDateString()}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        this.$snotify.success('Template exported successfully');
                    },
                    err => {
                        this.isLoading = false;
                    }
                )
            },
            checkCategory(event){
                // console.log(event);
                this.checkOrder();
            },
            checkOrder(event) {
            // console.log(this.form.category_id);
               const data ={
                   client_id: JSON.parse(localStorage.getItem('client_id')),
                   orderNo : event,
                   category_id : this.form.category_id,
                   } 
                  API.checkOrder(
                    data,
                    data => {
                        if(data.status === 200) {
                            this.form.order = data.nextOrder;
                        }
                        else if(data.status === 500) {
                            this.form.order = data.nextOrder;
                            this.$snotify.error(data.message);
                        }
                    },
                    err => {
                    }
                )

            },
            getCategories() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getCategories(
                    data,
                    data => {
                        this.categories = data.data;
                        this.categories.forEach((item, index) => {
                            if(index == 0) {
                                this.form.category_id = item.id;
                            }
                        })
                        this.$v.form.$reset();
                        this.checkOrder();
                    },
                    err => {
                    }
                )
            },
            deleteQuestion(e) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        API.deleteQuestion(
                            e,
                            data => {
                                this.loadItems();
                                this.$snotify.success('Record has been deleted!');
                                this.form.id = '';
                                this.form.logic = false;
                                this.form.question = '';
                                this.form.response = '';
                                this.$v.form.$reset();
                                this.showSubQuestions = false;
                            },
                            err => {
                            })
                    }
                })
            },
            editQuestion(e) {
                window.scrollTo({top: 0, behavior: 'smooth'});
                this.$v.form.$reset();
                API.editQuestion(
                    e, data => {
                        if(!data.data.category.show) {
                            this.$snotify.error('Category is disabled for selected question.');
                            this.categories = [
                                {'id': data.data.category_id, 'name': data.data.category.name}
                            ]
                        } else {
                            this.categories = data.categories;
                        }
                        // console.log(data)
                        this.form.category_id = data.data.category_id;
                        this.form.order = data.data.order;
                        this.form.question = data.data.question;
                        this.form.required = (data.data.required == 1) ? true : false;
                        this.form.show = (data.data.show == 1) ? true : false;
                        this.form.spotter_question = (data.data.spotter_question == 1) ? true : false;
                        this.form.map_filterable = (data.data.map_filterable == 1) ? true : false;
                        this.form.logic = (data.data.logic == 1) ? true : false;
                        this.form.show_on_spotter = (data.data.show_on_spotter == 1) ? true : false;
                        this.form.show_answer_on_spotter = (data.data.show_answer_on_spotter == 1) ? true : false;
                        this.form.is_countable = (data.data.is_countable == 1) ? true : false;
                        this.form.chart_answer = (data.data.chart_answer == 1) ? true : false;

                        this.form.id = data.data.id;
                        this.form.response = data.data.question;
                        this.orderValue = data.data.order;
                        this.orderError = false;
                        // this.form.response = data.data.question;

                        let text1 = [];
                        if (data.data.responses.length > 0) {
                            data.data.responses.forEach((values, index) => {
                                text1.push(values.name);
                            });
                            this.form.response = text1.toString();
                        } else {
                            this.form.response = '';
                        }

                        if (this.form.logic) {
                            this.showSubQuestions = true;
                            if (data.data.logic_answer_type === 'Yes') {
                                this.form.response = 'yes';

                                this.form.subQuestions = [];
                                data.data.sub_questions.forEach((values, index) => {
                                    let sub_q_ans = [];
                                    let sub_q_ans_str = '';
                                    if (values.sub_questions_response.length > 0) {
                                        values.sub_questions_response.forEach((values, index) => {
                                            sub_q_ans.push(values.name)
                                        })
                                        sub_q_ans_str = sub_q_ans.toString();
                                    }
                                    this.form.subQuestions.push({
                                        id: values.id,
                                        question: values.question,
                                        answer: sub_q_ans_str
                                    });
                                });
                                
                                
                            } else if(data.data.logic_answer_type === 'No') {
                                this.form.response = 'no';
                            } else if(data.data.logic_answer_type === 'CBD') {
                                this.form.response = 'CBD';
                            }
                        } else {
                            this.showSubQuestions = false;
                        }

                    },
                    err => {
                    }
                )
            },
            onSubmit() {
                // this.checkOrder(this.form.order);
                // console.log(this.form.order);
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    // this.isDisabled = true;
                    return;
                }
                if(this.orderError){
                    this.orderError = true;
                    this.isDisabled = false;
                    return false;
                }else{
                    this.isDisabled = false;
                }
                if (this.form.question != '') {
                    this.isDisabled = true;
                    API.addEditQuestion(
                        this.form,
                        data => {
                            let flag = false;
                            if (data.status == 500) {
                                this.isDisabled = false;
                                this.$snotify.error(data.message);
                                this.editQuestion(data.id);
                                flag = true;
                            } else if (data.status == 300) {
                                this.isDisabled = false;
                                this.$snotify.error(data.message);
                                flag = true;
                            } else {
                                flag = false;
                            }
                            if (flag) {
                                return;
                            }
                            this.isDisabled = false;
                            this.$v.form.$reset();
                            this.$snotify.success(data.message, {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            // this.form.category_id = '';
                            this.form.question = "";
                            this.form.response = "";
                            this.form.required = true;
                            this.form.logic = false;
                            this.form.show_on_spotter = false;
                            this.form.show_answer_on_spotter = true;
                            this.form.is_countable = false;
                            this.form.chart_answer = false;
                            this.form.required = true;
                            this.form.show = true;
                            this.form.spotter_question = true;
                            this.form.map_filterable = false;
                            this.form.id = "";
                            this.getCategories();
                            this.loadItems();
                            this.orderValue = '';
                            this.checkOrder();
                            this.$v.form.$reset();
                            this.showSubQuestions = false;
                        },
                        err => {
                            this.isDisabled = false;
                            this.$v.form.$reset();
                            this.$snotify.error(err);
                        }
                    )

                } else {
                    this.isDisabled = false;
                    this.$snotify.warning('Please fill required fields', {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });

                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            BulkEdit() {
                 const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    category_id: this.form.category_id,
                }
                API.getAllQuestionsOrder(data,
                    data => {
                        // this.totalRecords = data.totalRecords;
                        this.rowsForBulkEdit = data.data;
                      
                    },
                    err => {
                    }
                )
            }, 
            loadItems() {
                this.isLoading = true;
                API.getAllQuestions(this.serverParams,
                    data => {
                        // console.log(data.data.data[0].client_id);
                        this.version_id = data.version_id;
                        this.is_pro_sync = data.is_pro_sync;
                        this.isLoading = false;
                        this.totalRecords = data.totalRecords;
                        this.rows = data.data.data;
                        console.log(this.rows);
                        // this.form.category_id = data.data.data[0].client_id;
                        // this.users = data
                    },
                    err => {
                    }
                )
            }, formatFn: function (value) {
                if (value === 1) {
                    return 'Yes';
                }
                if (value === 0) {
                    return 'No';
                } else {
                    return 'No';
                }
            },
            formatCategory(value) {
                if (value != null && typeof value['name'] != 'undefined') {
                    return value['name'];
                }
            },
            formatResponses(value) {
                let text1 = [];
                if (value.length > 0) {
                    value.forEach((values, index) => {
                        text1.push(values.name);
                    });
                    return text1.toString();
                }
                else return null;
            },
            searchFn(params) {
                this.updateParams({searchTerm: params});
                this.loadItems();
                this.isLoading = false;
            },
            onChangeEventHandler(event) {
                if (event.value === true) {
                    // this.form.question = '';
                    // this.form.response = '';
                    this.form.subQuestions = [];
                    this.form.subQuestions.push({
                        question: '',
                        answer: ''
                    })
                    this.showSubQuestions = true;
                } else {
                    // this.form.question = '';
                    // this.form.response = '';
                    // this.form.subQuestions = [];
                    this.form.subQuestions.push({
                        question: '',
                        answer: ''
                    })
                    this.showSubQuestions = false;  
                }
            },
            onChangeAnswer(event) {
                if (event === 'yes') {
                    this.form.subQuestions = [];
                    this.form.subQuestions.push({
                        question: '',
                        answer: ''
                    })
                }
            },
            addQuestion() {
                this.form.subQuestions.push({
                    question: '',
                    answer: ''
                })
            },
            removeQuestion(val) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        var index = this.form.subQuestions.indexOf(val);
                        if (this.form.id && val.id) {
                            let data = {
                                question_id: this.form.id,
                                sub_question_id: val.id,
                            }
                            API.deleteSubQuestion(
                                data,
                                data => {
                                    this.$swal(
                                        'Deleted!',
                                        'Record has been deleted.',
                                        'success'
                                    )
                                },
                                err => {
                                })
                        }
                        if (index !== -1) {
                            this.form.subQuestions.splice(index, 1);
                        }
                    }
                })
            },
            getProjectNames() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                };
                API.getProjectName(
                    data,
                    response => {
                        this.projectOptions = response.data.map(project => ({
                            id: project.id,
                            name: project.project_name
                        }));
                        if (this.projectOptions.length > 0) {
                            // this.selectedProjectId = this.projectOptions;
                            // this.serverParams.selectedProjectId = this.selectedProjectId;
                            this.loadItems();  // Load items for the selected project
                        }
                    },
                    err => {
                        console.error('Error fetching project names:', err);
                    }
                );
            },
            onProjectChange() {
                this.serverParams.selectedProjectId = this.selectedProjectId;
                this.loadItems();
            },

        }, computed: {},
        mounted() {
            this.isLoading = true;
            this.loadItems();
            this.BulkEdit();
            this.getCategories();
            this.getProjectNames();
            // var nextOrder;
            for (var input = 1; input <= 100; input++) {
                // if (input == 1) {
                //     this.form.order = nextOrder;
                // }
                
                this.orders.push(input);
            }
        },
        afterCreated(){
            console.log(this.form.category_id);
        }
    }
</script>

<style>
    .shared-data{
        text-align: center;
        text-align: center;
        color: #787878;
        font-weight: 500;
        font-size: 1.25rem;
    }
    .question-order {
        flex-direction: column;
        align-items: flex-start !important;
    }
    .question-order .btn{
        padding: 10px;
    }
    .row-question-order {
        flex-direction: row;
    }
    @media (min-width: 1450px) {
        .pd-table table.vgt-table td {
        padding: 0.75em 0.5em 0.75em 0.5em;
        }
        .pd-table .vgt-table th {
            padding: 0.75em 0.5em 0.75em 0.5em;
        }
        .pd-table .vgt-table th:nth-child(1) {
        width: 66px !important;
        }
        .pd-table .vgt-table th:nth-child(2) {
            width: 88px !important;
        }
        .pd-table .vgt-table th:nth-child(10) {
            width: 85px !important;
        }
        .pd-table .vgt-table th:nth-child(11) {
            width: 92px !important;
        }
        .vgt-table.bordered td {
            word-break: break-all;
        }
    }
    @media (max-width: 1150px) {
        .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
    @media (max-width: 550px) {
        .row-question-order {
            flex-direction: column;
        }
    }
</style>